.mainfooterContainer {
  padding: 2rem 1rem;
  font-size: 0.75rem;
  background-color: #f7fafc;
  border-top: 1px solid #e2e8f0;
}

.mainfooterContainer p {
  margin: 0;
  padding: 0;
}

.mainfooterWrapper {
  margin: auto;
  width: 100%;
  max-width: 1280px;
}

.mainfooter {
  margin-top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  -webkit-box-pack: justify;
  flex-direction: row;
  background-color: #f7fafc;
  font-family: Arial, sans-serif;
  color: #000;
}

.mainfooterSection {
  flex: 1;
  max-width: 300px;
}

.mainfooterSection p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.footerAbout {
  margin-top: 1rem;
}

.mainfooterLogo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mainfooterLogo .svgPlaceholder {
  width: fit-content;
  display: inline-block;
  background: transparent;
  margin-left: -16px;
}

.mainfooterLogo .tagline {
  font-size: 14px;
  color: #000;
}

.mainfooterLinks {
  display: grid;
  grid-gap: 1.9rem;
  grid-template-columns: repeat(4, minmax(0, 150px));
  grid-auto-rows: minmax(min-content, max-content);
  font-size: 0.75rem;
  font-weight: 600;
  a.footerFLink {
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 500;
    transition: 0.3s ease all;
    position: relative;
    &:hover {
        padding-left: 20px;
    }
    &:before {
      content: "";
      width: 0;
      height: 2px;
      background: var(--marketplaceColor);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.3s ease all;
  }
    &:hover:before {
        width: 10px;
    }
  }
}
.footerLink {
  padding-top: 40px;
}
.mainfooterLinks p {
  font-weight: 600;
}
 
.mainfooterLinks div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mainfooterSocial {
  width: 8rem;
  margin-top: 1rem;
  flex-shrink: 0;
}

.mainfooterSocial .socialIcons {
  display: flex;
  gap: 10px;
}

.mainfooterSocial .svgPlaceholder {
  height: 24px;
  width: 24px;
  color: #000;
  display: inline-block;
}

.mainfooterSocial .terms {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mainfooterSocial .terms a {
  font-size: 16px;
  color: #000;
}

.svgPlaceholder {
  display: inline-block;
  background-color: #ddd;
  text-align: center;
  vertical-align: middle;
  font-size: 10px;
  color: #777;
}

@media (max-width: 10279px) {
  .mainfooterWrapper {
    max-width: 100vw;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 767px) {
  .mainfooter {
    flex-direction: column;
    display: -webkit-flex;
    gap: 20px;
  }

  .terms {
    width: 100%;
  }

  .mainfooterSocial {
    width: 100%;
  }

  .mainfooterContainer {
    padding: 8rem 0;
    font-size: 0.875rem;
  }
  .mainfooterWrapper {
    max-width: 768px;
  }

  .footerAbout {
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .mainfooterLinks{
    grid-template-columns: repeat(2, minmax(0, 150px));
  }

  .mainfooterSection{
    max-width: 100%;
  }
}

